import React, { useState, useEffect, useRef } from 'react'
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import cn from 'classnames'
import ReCAPTCHA from 'react-google-recaptcha'
import AnimateHeight from 'react-animate-height'
import OtpInput from 'react-otp-input'
import { ParsedUrlQuery } from 'querystring'
import { cssTransition, toast } from 'react-toastify'

import UserUtils from '@utils/UserUtils'
import useUser from '@hooks/useUser'
import Api from '@api'
import { ApiErrorResponseType, UserSession } from '@custom-types/common'
import { LoginResponseType } from '@custom-types/Auth'
import { TeamType } from '@custom-types/Organization'


const Login = () => {
  const { user: session, mutateUser } = useUser({
    redirectIfFound: false,
  });
  const router = useRouter();
  const captchaRef = useRef<ReCAPTCHA>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [isInvited, setIsInvited] = useState<boolean>(false);
  const [user, setUser] = useState<UserSession>();
  const [routerQuery, setRouterQuery] = useState<ParsedUrlQuery>();
  const [twoFactorType, setTwoFactorType] = useState<string | null>(null);
  const [mobileNumber, setMobileNumber] = useState<string>();
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [retrievingUser, setRetrievingUser] = useState<boolean>(false);
  const [animateError, setAnimateError] = useState<boolean>(false);
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false);
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const [show2fa, setShow2fa] = useState<boolean>(false);
  const [resending, setResending] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>('');
  const opacity = cssTransition({
    enter: "transition-opacity duration-300 ease-in-out opacity-100",
    exit: "transition-opacity duration-300 ease-in-out opacity-0",
  });

  useEffect(() => {
    emailRef?.current?.focus();
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (animateError) {
      timer = setTimeout(() => {
        setAnimateError(false);
      }, 400);
    }

    return () => clearTimeout(timer);
  }, [animateError]);

  useEffect(() => {
    if (router) {
      setRouterQuery(router.query);
      const { account_invite } = router.query;
      if (account_invite) {
        setIsInvited(true);
      }
    }
  }, [router])

  useEffect(() => {
    if (session) {
      console.log('isLoggedIn', session.isLoggedIn);
      if (session.isLoggedIn) {
        if (router) {
          router.replace({ pathname: '/', query: routerQuery});
        }
      } else {
        if (session.token) {
          setMobileNumber(session.mobile_number);
          if (session.settings) {
            if (session.settings.two_factor) {
              setTwoFactorType(session.settings.two_factor.active_type);
            }
          } else {
            if (session.two_factor_type) {
              setTwoFactorType(session.two_factor_type);
            }
          }
        }
      }
    }
  }, [session, router])

  useEffect(() => {
    if (user) {
      if (user.two_factor) {
        if (user.two_factor_type) {
          setTwoFactorType(user.two_factor_type)
        }
        if (user.mobile_number) {
          setMobileNumber(user.mobile_number);
        }
      }
    }
  }, [user]);

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    if (value.length < 1) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
    if (value.length < 1) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  const onTogglePasswordVisible = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setPasswordVisible(!passwordVisible);
  };

  const onLoginSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    await login();
  }

  const onLoginClicked = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    await login();
  }

  const login = async () => {
    if (!email) {
      setAnimateError(true);
      setEmailError(true);
      emailRef?.current?.focus();
    } else if (!UserUtils.isValidEmail(email)) {
      setAnimateError(true);
      setEmailError(true);
      emailRef?.current?.focus();
    } else if (!password) {
      setAnimateError(true);
      setPasswordError(true);
      passwordRef?.current?.focus();
    } else {
      // both email and password has values
      setLoading(true);
      const recaptcha = await captchaRef?.current?.executeAsync();
      captchaRef?.current?.reset();

      if (recaptcha) {
        const response = await Api.login({ email, password, recaptcha });

        if (response.ok) {
          setLoginError(undefined);
          const user: UserSession = response.data;
          setUser(user);
          if (user.two_factor) {
            setLoading(false);
            setShow2fa(true);
          } else {
            setLoading(false);
            setRetrievingUser(true);
            setIsSignedIn(true);
            if (user.email_verified) {
              setIsEmailVerified(true);
              await mutateUser(user, true);
              if (user.settings) {
                if (user.settings.livemode) {
                  router.replace({ pathname: '/dashboard', query: routerQuery});
                } else {
                  router.replace({ pathname: '/test/dashboard', query: routerQuery});
                }
              } else {
                router.replace({ pathname: '/test/dashboard', query: routerQuery});
              }
            } else {
              setRetrievingUser(false);
              setIsEmailVerified(false);
            }
          }
        } else {
          setLoading(false);
          setAnimateError(true);
          const error: ApiErrorResponseType = response.data;
          if (error.errors) {
            const { errors } = error;
            if (errors.email) {
              setEmailError(true);
              setLoginError(errors.email[0]);
            }
          } else {
            if (error.message) {
              setLoginError(error.message);
            } else {
              setLoginError("Something went wrong. Please try again.");
            }
          }
        }
      } else {
        setLoading(false);
        setAnimateError(true);
        setLoginError("Something went wrong. Please try again.");
      }
    }
  };

  const onSendVerificationEmail = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (email) {
      setLoading(true);
      const response = await Api.sendVerificationEmail(email);
      if (response.ok) {
        console.log("response", response.data);
        setLoading(false);
        setIsEmailSent(true);
      }
    }
  };

  const onSkip = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (user) {
      await mutateUser(user, true)
      if (user.settings) {
        if (user.settings.livemode) {
          router.replace({ pathname: '/dashboard', query: routerQuery});
        } else {
          router.replace({ pathname: '/test/dashboard', query: routerQuery});
        }
      } else {
        router.replace({ pathname: '/test/dashboard', query: routerQuery});
      }
    }
  };

  const onResend = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (user) {
      if (user.token) {
        setResending(true);
        const response = await Api.loginResend(user.token);
        if (response.ok) {
          setResending(false);
          toast(
            <div className="flex flex-row items-center justify-items-center px-3">
              <div className="flex text-green-500 mr-3">
                <svg
                  className="w-4 h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <span className="text-white inline text-sm leading-5 font-normal proportional-nums whitespace-normal">
                OTP code sent
              </span>
            </div>,
            {
              autoClose: 7000,
              position: toast.POSITION.BOTTOM_CENTER,
              transition: opacity,
            }
          );
        } else {
          setResending(false);
          toast(
            <div className="flex flex-row items-center justify-items-center px-3">
              <div className="flex text-red-500 mr-3">
                <svg
                  className="w-4 h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <span className="text-white inline text-sm leading-5 font-normal proportional-nums whitespace-normal">
                A problem occurred while sending you an SMS. Try again later.
              </span>
            </div>,
            {
              autoClose: 7000,
              position: toast.POSITION.BOTTOM_CENTER,
              transition: opacity,
            }
          );
        }
      }
    }
  };

  const onOtpChanged = (value: string) => {
    setVerificationCode(value);
  };

  const onVerifySubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    await verifyOtp();
  }

  const onVerifyOtp = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    await verifyOtp();
  }

  const verifyOtp = async () => {
    if (verificationCode.length < 6) {
      setLoginError("Incomplete verification code");
      return;
    }

    if (user) {
      if (user.token) {
        setLoading(true);
        const response = await Api.login2fa({
          token: user.token,
          pin: verificationCode,
        });
        if (response.ok) {
          const user: UserSession = response.data;
          setUser(user);
          setLoading(false);
          setRetrievingUser(true);
          setIsSignedIn(true);
          if (user.email_verified) {
            setIsEmailVerified(true);
            await mutateUser(user, true);
            if (user.settings) {
              if (user.settings.livemode) {
                router.replace({ pathname: '/dashboard', query: routerQuery});
              } else {
                router.replace({ pathname: '/test/dashboard', query: routerQuery});
              }
            } else {
              router.replace({ pathname: '/test/dashboard', query: routerQuery});
            }
          } else {
            setRetrievingUser(false);
            setIsEmailVerified(false);
          }
        } else {
          setLoading(false);
          setAnimateError(true);
          setLoginError(
            response.data.message || "Something went wrong. Please try again."
          );
        }
      }
    }
  };

  return (
    <div className="h-screen bg-white">
      <Head>
        <title>AUB PayMate: Sign in</title>
        <link rel="stylesheet" href="/css/auth.css" />
      </Head>
      <div className="h-full min-h-full bg-white flex flex-col">
        {/* Background */}
        <div>
          <div className="fixed left-0 right-0 -top-62 bottom-0 z-0 transform -skew-y-12">
            <div className="absolute -top-250 left-0 right-0 origin-0-50 h-425 bg-offset"></div>
          </div>
        </div>
        {/* Content */}
        <div className="my-0 mx-4 z-0 relative flex flex-row h-full pt-14 justify-center">
          <div className="flex flex-col w-96 desktop:w-1/2 desktop:min-w-1/2 ultraWide:w-135 ultraWide:min-w-135">
            <div className="pb-10 pl-5">
              <img
                className="h-10 w-auto"
                src="/images/aub_paymate.png"
                alt="AUB PayMate"
              />
            </div>
            <div
              className={cn(
                "flex-shrink-0 bg-white overflow-hidden shadow-card-lg rounded",
                {
                  "animate-shake": animateError,
                }
              )}
            >
              <div className="py-8 px-0 desktop:py-14 desktop:px-12">
                <div>
                  <div className="px-5">
                    <div role="main">
                      {retrievingUser && (
                        <div className="flex flex-col items-center justify-center py-12 px-8">
                          <div className="w-8 h-8 text-gray-400">
                            <svg
                              className="w-8 h-8 origin-center animate-spinner-spin"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24px"
                              height="24px"
                              viewBox="0 0 100 100"
                              preserveAspectRatio="xMidYMid"
                            >
                              <circle
                                cx="50"
                                cy="50"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="5"
                                r="36"
                                strokeDasharray="169.64600329384882 58.548667764616276"
                                transform="matrix(1,0,0,1,0,0)"
                                style={{
                                  transform: "matrix(1, 0, 0, 1, 0, 0)",
                                }}
                              ></circle>
                            </svg>
                          </div>
                          <div className="pt-2">
                            <span className="text-sm text-gray-500">
                              Signing in...
                            </span>
                          </div>
                        </div>
                      )}
                      {!retrievingUser ? (
                        !isSignedIn ? (
                          show2fa ? (
                            <div className="w-full pb-3 pointer-events-none">
                              <form onSubmit={onVerifySubmit} noValidate>
                                <div className="flex flex-col justify-start flex-nowrap -ml-6 -mt-6">
                                  <div className="mt-6 ml-6 pointer-events-auto">
                                    <div className="text-2xl font-medium leading-8 tracking-tight text-body">
                                      <span>Two-step authentication</span>
                                    </div>
                                  </div>
                                  <div className="mt-6 ml-6 pointer-events-auto">
                                    <div className="pointer-events-none">
                                      <div className="flex flex-col justify-start flex-nowrap -ml-2 -mt-2">
                                        <div className="mt-2 ml-2 pointer-events-auto">
                                          <span className="inline text-sm font-normal leading-5 proportional-nums whitespace-normal">
                                            <div className="pointer-events-none">
                                              <div className="flex flex-col justify-start flex-nowrap">
                                                <div className="pointer-events-auto">
                                                  {
                                                    twoFactorType === 'sms' &&
                                                    <span>To continue, please enter the 6-digit verification code sent to <strong className="font-medium">{`your phone ending in ${mobileNumber ? mobileNumber.substring(mobileNumber.length - 4) : ''}`}</strong>.</span>
                                                  }
                                                  {
                                                    twoFactorType === 'authenticator' &&
                                                    <span>To continue, please enter the 6-digit verification code generated by <strong className="font-medium">your authenticator app</strong>.</span>
                                                  }
                                                </div>
                                                {
                                                  twoFactorType === 'sms' && 
                                                  <div className="pointer-events-auto">
                                                    <div className="py-4">
                                                      <span>
                                                        <span>Didn't receive a code? </span>
                                                        {
                                                          resending ? <span className="font-medium">Sending</span> : <a className="font-medium text-magpie-500 no-underline cursor-pointer focus:outline-none focus:ring-0" onClick={onResend}>Resend</a>
                                                        }
                                                        <span>.</span>
                                                      </span>
                                                    </div>
                                                  </div>
                                                }
                                              </div>
                                            </div>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-6 ml-6 pointer-events-auto">
                                    <div>
                                      <OtpInput
                                        value={verificationCode}
                                        shouldAutoFocus={show2fa}
                                        numInputs={6}
                                        isInputNum
                                        containerStyle="flex items-center justify-evenly"
                                        inputStyle={cn("OtpInput inline-flex items-center justify-center cursor-text rounded z-2 p-0 flex-1-auto bg-white border text-3xl proportional-nums whitespace-normal transition duration-250 focus:outline-none", {
                                          "border-gray-300 focus:border-magpie-300 focus:ring focus:ring-magpie-200 focus:ring-opacity-50": !loginError,
                                          "border-red-500 focus:border-red-500 focus:ring focus:ring-red-200 focus:ring-opacity-50": loginError
                                        })}
                                        focusStyle=""
                                        errorStyle=""
                                        onChange={(e: string) => onOtpChanged(e)}
                                        hasErrored={!!loginError}
                                      />
                                      <AnimateHeight
                                        height={ loginError ? 'auto' : 0}
                                        animateOpacity
                                        className="transition-height duration-250 ease-in-out"
                                      >
                                        <div role="alert" className="pt-2 flex flex-row items-center leading-5">
                                          <div className="pr-2 text-red-500">
                                            <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
                                          </div>
                                          <span className="inline text-sm text-red-500 proportional-nums whitespace-normal">{loginError}</span>
                                        </div>
                                      </AnimateHeight>
                                    </div>
                                  </div>
                                  <div className="mt-6 ml-6 pointer-events-auto">
                                    <button type="submit" disabled={loading} className={cn("py-2 px-4 rounded min-h-11 h-full w-full inline-flex text-base leading-7 font-medium cursor-default text-white border-none bg-magpie-500 focus:outline-none transition duration-250 ease-linear focus:ring focus:ring-opacity-50 focus:ring-magpie-200", {
                                      "opacity-60": loading,
                                      "opacity-100": !loading
                                    })} onClick={onVerifyOtp}>
                                      <div className="flex align-baseline flex-row-reverse w-full relative">
                                        <div className="flex align-baseline flex-row-reverse justify-center w-full leading-0 flex-1-auto">
                                          <span className={cn("block text-white text-base font-medium leading-7 proportional-nums whitespace-nowrap transition duration-250 ease-linear -mt-px", {
                                            "opacity-100": !loading,
                                            "opacity-0": loading
                                          })}>Continue</span>
                                          <div className={cn("spinner-icon block absolute top-1/2 transform -translate-y-1/2 opacity-0 transition-all duration-200 -mt-px", {
                                            'opacity-0': !loading,
                                            'opacity-100': loading
                                          })}>
                                            <div className="w-5 h-5">
                                              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" focusable="false">
                                                <ellipse className="origin-center animate-spinner-spin" fillRule="evenodd"  cx="12" cy="12" rx="10" ry="10" style={{ stroke: 'rgba(255, 255, 255)' }}></ellipse>
                                              </svg>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                  </div>
                                  <div className="mt-6 ml-6 pointer-events-auto">
                                    <div className="flex justify-center">
                                      <span className="block text-sm text-gray-500 font-normal leading-5 proportional-nums whitespace-nowrap">
                                        <span>Having trouble logging in? </span>
                                        <Link href="mailto:support@magpie.im" passHref>
                                          <a target="_blank" className="font-medium no-underline text-magpie-500 hover:text-gray-900 focus:outline-none focus:ring-0">Contact our support</a>
                                        </Link>
                                        <span>.</span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          ) : (
                            <div className="pointer-events-none">
                              <div className="flex flex-col justify-start flex-nowrap -ml-5 -mt-5">
                                <div className="mt-5 ml-5 pointer-events-auto">
                                  <h1 className="text-2xl font-bold tracking-tight proportional-nums">
                                    Sign in to your account
                                  </h1>
                                </div>
                                {
                                  isInvited ? (
                                    <div className="mt-5 ml-5 pointer-events-auto">
                                      <div className="overflow-hidden shadow-keyline rounded">
                                        <div className="bg-magpie-50 py-4 px-5">
                                          <span className="text-sm inline-block text-body leading-5 proportional-nums whitespace-normal">
                                            {`You've been invited to join a team on AUB PayMate. If you don't have a AUB PayMate account yet, `}
                                            <Link href={{ pathname: '/register', query: routerQuery }}>
                                              <a className="m-0 p-0 no-underline font-medium text-magpie-600 proportional-nums whitespace-normal">create your account here</a>
                                            </Link>
                                            {`.`}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null
                                }
                                <div className="mt-5 ml-5 pointer-events-auto">
                                  <form onSubmit={onLoginSubmit} noValidate>
                                    <div className="pb-3 pointer-events-none">
                                      <div className="flex flex-col justify-start flex-nowrap">
                                        <div className="pointer-events-auto">
                                          <div className="pointer-events-none">
                                            <div className="flex flex-col justify-start flex-nowrap -ml-3 -mt-3">
                                              <div className="mt-3 ml-3 pointer-events-auto">
                                                <label
                                                  htmlFor="email"
                                                  className="cursor-pointer inline text-sm font-medium proportional-nums whitespace-normal"
                                                >
                                                  Email
                                                </label>
                                              </div>
                                              <div className="mt-3 ml-3 pointer-events-auto">
                                                <input
                                                  ref={emailRef}
                                                  id="email"
                                                  name="email"
                                                  type="email"
                                                  autoComplete="email"
                                                  autoCapitalize="none"
                                                  aria-invalid={emailError}
                                                  className={cn(
                                                    "inline-flex w-full max-w-full cursor-text rounded min-h-11 text-gray-900 text-base leading-7 bg-white border select-auto focus:ring focus:ring-opacity-50",
                                                    {
                                                      "border-gray-200 focus:outline-none focus:border-magpie-300 focus:ring-magpie-200":
                                                        !emailError,
                                                      "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-300":
                                                        emailError,
                                                    }
                                                  )}
                                                  value={email}
                                                  onChange={(e) =>
                                                    onEmailChange(e)
                                                  }
                                                />
                                              </div>
                                              <div className="hidden mt-3 ml-3 pointer-events-auto"></div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="pointer-events-auto">
                                          <div className="pt-8"></div>
                                        </div>
                                        <div className="pointer-events-auto">
                                          <div className="flex">
                                            <div className="transform-gpu translate-x-0 translate-y-0 scale-x-100 scale-y-100 origin-0-0 w-full">
                                              <div className="pointer-events-none">
                                                <div className="flex flex-col justify-start flex-nowrap -mt-3 -ml-3">
                                                  <div className="mt-3 ml-3 pointer-events-auto">
                                                    <label htmlFor="password">
                                                      <span className="cursor-pointer inline text-sm font-medium proportional-nums whitespace-normal">
                                                        <div className="pointer-events-none">
                                                          <div className="flex flex-row justify-between flex-nowrap -ml-1 -mt-1">
                                                            <div className="mt-1 ml-1 pointer-events-auto">
                                                              <span>
                                                                Password
                                                              </span>
                                                            </div>
                                                            <div className="mt-1 ml-1 pointer-events-auto">
                                                              <Link href="/reset">
                                                                <a className="text-magpie-600 no-underline hover:text-magpie-400">
                                                                  Forgot your
                                                                  password?
                                                                </a>
                                                              </Link>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </span>
                                                    </label>
                                                  </div>
                                                  <div className="mt-3 ml-3 pointer-events-auto">
                                                    <div className="relative">
                                                      <input
                                                        ref={passwordRef}
                                                        id="password"
                                                        name="password"
                                                        type={
                                                          passwordVisible
                                                            ? "text"
                                                            : "password"
                                                        }
                                                        autoComplete="current-password"
                                                        autoCapitalize="none"
                                                        aria-invalid={
                                                          passwordError
                                                        }
                                                        className={cn(
                                                          "inline-flex w-full max-w-full pr-14 cursor-text rounded min-h-11 text-gray-900 text-base leading-7 bg-white border select-auto focus:ring focus:ring-opacity-50",
                                                          {
                                                            "border-gray-200 focus:outline-none focus:border-magpie-300 focus:ring-magpie-200":
                                                              !passwordError,
                                                            "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-300":
                                                              passwordError,
                                                          }
                                                        )}
                                                        value={password}
                                                        onChange={
                                                          onPasswordChange
                                                        }
                                                      />
                                                      <button
                                                        disabled={!password}
                                                        type="button"
                                                        className={cn(
                                                          "flex absolute inset-y-0 right-0 pr-3 items-center text-gray-400 hover:text-gray-700 transition duration-250 ease-in-out focus:outline-none",
                                                          {
                                                            "opacity-100 cursor-pointer":
                                                              password,
                                                            "opacity-0 cursor-default":
                                                              !password,
                                                          }
                                                        )}
                                                        onClick={
                                                          onTogglePasswordVisible
                                                        }
                                                      >
                                                        {passwordVisible ? (
                                                          <svg
                                                            className="w-5 h-5"
                                                            fill="currentColor"
                                                            viewBox="0 0 20 20"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                          >
                                                            <path
                                                              fillRule="evenodd"
                                                              d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                                                              clipRule="evenodd"
                                                            ></path>
                                                            <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z"></path>
                                                          </svg>
                                                        ) : (
                                                          <svg
                                                            className="w-5 h-5"
                                                            fill="currentColor"
                                                            viewBox="0 0 20 20"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                          >
                                                            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                                                            <path
                                                              fillRule="evenodd"
                                                              d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                                                              clipRule="evenodd"
                                                            ></path>
                                                          </svg>
                                                        )}
                                                      </button>
                                                    </div>
                                                  </div>
                                                  <AnimateHeight
                                                    height={
                                                      loginError ? "auto" : 0
                                                    }
                                                    className="mt-3 ml-3 pointer-events-auto"
                                                    duration={300}
                                                    animateOpacity
                                                  >
                                                    <div className="pt-1 pl-px flex flex-row">
                                                      <div className="flex items-center flex-row relative">
                                                        <div className="flex-1 pr-1">
                                                          <div className="flex text-red-500">
                                                            <svg
                                                              className="w-4 h-4 m"
                                                              fill="currentColor"
                                                              viewBox="0 0 20 20"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                              <path
                                                                fillRule="evenodd"
                                                                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                                                clipRule="evenodd"
                                                              ></path>
                                                            </svg>
                                                          </div>
                                                        </div>
                                                        <span className="text-red-500 inline text-sm font-medium proportional-nums whitespace-normal">
                                                          {loginError}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </AnimateHeight>
                                                </div>
                                              </div>
                                              <div className="pt-8"></div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="pointer-events-auto">
                                          <div className="transform-gpu translate-x-0 translate-y-0 scale-x-100 scale-y-100 origin-0-0">
                                            <div className="pointer-events-none">
                                              <div className="flex flex-col justify-start flex-nowrap -mt-3 -ml-3">
                                                <div className="mt-3 ml-3 pointer-events-auto">
                                                  <button
                                                    type="submit"
                                                    disabled={loading}
                                                    className={cn(
                                                      "py-2 px-4 rounded min-h-11 h-full w-full inline-flex text-base leading-7 font-medium cursor-default text-white border-none bg-magpie-500 focus:outline-none transition duration-250 ease-linear focus:ring focus:ring-opacity-50 focus:ring-magpie-200",
                                                      {
                                                        "opacity-60": loading,
                                                        "opacity-100": !loading,
                                                      }
                                                    )}
                                                    onClick={(e) =>
                                                      onLoginClicked(e)
                                                    }
                                                  >
                                                    <div className="flex align-baseline flex-row-reverse w-full relative">
                                                      <div className="flex align-baseline flex-row-reverse justify-center w-full leading-0 flex-1-auto">
                                                        <span
                                                          className={cn(
                                                            "block text-white text-base font-medium leading-7 proportional-nums whitespace-nowrap transition duration-250 ease-linear -mt-px",
                                                            {
                                                              "opacity-100":
                                                                !loading,
                                                              "opacity-0":
                                                                loading,
                                                            }
                                                          )}
                                                        >
                                                          Continue
                                                        </span>
                                                        <div
                                                          className={cn(
                                                            "spinner-icon block absolute top-1/2 transform -translate-y-1/2 opacity-0 transition-all duration-200 -mt-px",
                                                            {
                                                              "opacity-0":
                                                                !loading,
                                                              "opacity-100":
                                                                loading,
                                                            }
                                                          )}
                                                        >
                                                          <div className="w-5 h-5">
                                                            <svg
                                                              className="w-5 h-5"
                                                              fill="currentColor"
                                                              viewBox="0 0 24 24"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              focusable="false"
                                                            >
                                                              <ellipse
                                                                className="origin-center animate-spinner-spin"
                                                                fillRule="evenodd"
                                                                cx="12"
                                                                cy="12"
                                                                rx="10"
                                                                ry="10"
                                                                style={{
                                                                  stroke:
                                                                    "rgba(255, 255, 255)",
                                                                }}
                                                              ></ellipse>
                                                            </svg>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="pointer-events-auto">
                                          <div className="pt-8"></div>
                                        </div>
                                        <div className="pointer-events-auto">
                                          <div className="transform-gpu translate-x-0 translate-y-0 scale-x-100 scale-y-100 origin-0-0">
                                            <div className="pointer-events-none">
                                              <div className="flex flex-col justify-start flex-nowrap -ml-3 -mt-3">
                                                <div className="mt-3 ml-3 pointer-events-auto">
                                                  <div className="pointer-events-none">
                                                    <div className="flex items-center flex-col justify-center flex-nowrap">
                                                      <div className="pointer-events-auto">
                                                        <span className="text-sm">
                                                          Don't have an account?{" "}
                                                          <Link href={{ pathname: '/register', query: routerQuery }}>
                                                            <a className="text-magpie-600 font-medium no-underline hover:text-magpie-400">
                                                              Sign up
                                                            </a>
                                                          </Link>
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          )
                        ) : (
                          !isEmailVerified && (
                            <div className="w-full pb-3 pointer-events-none">
                              <div className="flex flex-col justify-start flex-nowrap -ml-6 -mt-6">
                                <div className="mt-6 ml-6 pointer-events-auto">
                                  <div className="pointer-events-none">
                                    <div className="flex flex-col justify-start flex-nowrap -ml-2 -mt-2">
                                      <div className="mt-2 ml-2 pointer-events-auto">
                                        <span className="text-gray-900 inline text-base font-medium leading-6 proportional-nums whitespace-normal">
                                          <div className="pb-3">
                                            <div className="text-2xl font-medium leading-8 tracking-tight text-body">
                                              <span>
                                                Your email isn't verified yet
                                              </span>
                                            </div>
                                          </div>
                                        </span>
                                      </div>
                                      <div className="mt-2 ml-2 pointer-events-auto">
                                        <span className="text-body inline text-sm font-normal leading-5 proportional-nums whitespace-normal">
                                          <span>
                                            The email associated with your
                                            AUB PayMate account needs to be
                                            confirmed. We can send{" "}
                                          </span>
                                          <Link
                                            href={`mailto:${email}`}
                                            passHref
                                          >
                                            <a
                                              target="_blank"
                                              rel="noopener"
                                              className="font-medium text-magpie-500 no-underline hover:text-gray-900 focus:outline-none focus:ring-0"
                                            >
                                              {email}
                                            </a>
                                          </Link>
                                          <span>
                                            {" "}
                                            a quick verification link.
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-6 ml-6 pointer-events-auto">
                                  <div className="pointer-events-none">
                                    <div className="flex flex-col justify-start flex-nowrap -ml-3 -mt-3">
                                      <div className="mt-3 ml-3 pointer-events-auto">
                                        <button
                                          type="submit"
                                          disabled={loading || isEmailSent}
                                          className={cn(
                                            "py-2 px-4 rounded min-h-11 h-full w-full inline-flex text-base leading-7 font-medium text-white border-none bg-magpie-500 focus:outline-none transition duration-250 ease-linear focus:ring focus:ring-opacity-50 focus:ring-magpie-200",
                                            {
                                              "opacity-60 cursor-default":
                                                loading || isEmailSent,
                                              "opacity-100 cursor-pointer":
                                                !loading && !isEmailSent,
                                            }
                                          )}
                                          onClick={onSendVerificationEmail}
                                        >
                                          <div className="flex align-baseline flex-row-reverse w-full relative">
                                            <div className="flex align-baseline flex-row-reverse justify-center w-full leading-0 flex-1-auto">
                                              <span
                                                className={cn(
                                                  "block text-white text-base font-medium leading-7 proportional-nums whitespace-nowrap transition duration-250 ease-linear -mt-px",
                                                  {
                                                    "opacity-100": !loading,
                                                    "opacity-0": loading,
                                                  }
                                                )}
                                              >
                                                {isEmailSent
                                                  ? "Email sent"
                                                  : "Send verification email"}
                                              </span>
                                              <div
                                                className={cn(
                                                  "spinner-icon block absolute top-1/2 transform -translate-y-1/2 opacity-0 transition-all duration-200 -mt-px",
                                                  {
                                                    "opacity-0": !loading,
                                                    "opacity-100": loading,
                                                  }
                                                )}
                                              >
                                                <div className="w-5 h-5">
                                                  <svg
                                                    className="w-5 h-5"
                                                    fill="currentColor"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    focusable="false"
                                                  >
                                                    <ellipse
                                                      className="origin-center animate-spinner-spin"
                                                      fillRule="evenodd"
                                                      cx="12"
                                                      cy="12"
                                                      rx="10"
                                                      ry="10"
                                                      style={{
                                                        stroke:
                                                          "rgba(255, 255, 255)",
                                                      }}
                                                    ></ellipse>
                                                  </svg>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </button>
                                      </div>
                                      <div className="mt-3 ml-3 pointer-events-auto">
                                        <button
                                          className="py-2 px-4 cursor-pointer rounded min-h-11 h-full w-full inline-flex justify-center text-sm leading-5 font-medium text-magpie-600 border-none bg-transparent focus:outline-none transition duration-250 ease-linear hover:text-gray-900 focus:ring-0"
                                          onClick={onSkip}
                                        >
                                          <span className="block text-sm font-medium leading-7 proportional-nums whitespace-nowrap transition duration-250 ease-linear -mt-px">
                                            Skip for now
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ReCAPTCHA
              ref={captchaRef}
              sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY || ""}
              size="invisible"
            />
            <div className="flex-grow flex-shrink"></div>
            <div className="relative py-6 px-5">
              <div className="pointer-events-none">
                <div className="flex flex-row justify-center flex-nowrap -ml-2 -mt-2">
                  <div className="mt-2 ml-2 pointer-events-auto">
                    <a
                      className="text-sm font-medium hover:text-gray-900"
                      href="https://magpie.im"
                      target="_self"
                    >
                      Powered by Magpie
                    </a>
                  </div>
                  <div className="mt-2 ml-2 pointer-events-auto">
                    <span className="text-gray-50">·</span>
                  </div>
                  <div className="mt-2 ml-2 pointer-events-auto desktop:hidden">
                    <a
                      className="text-sm font-medium hover:text-gray-900"
                      href="https://magpie.im/terms.html"
                      target="_self"
                    >
                      Privacy &amp; terms
                    </a>
                  </div>
                  <div className="hidden mt-2 ml-2 pointer-events-auto desktop:block">
                    <a
                      className="text-sm font-medium hover:text-gray-900"
                      href="https://magpie.im/terms.html"
                      target="_self"
                    >
                      Terms &amp; conditions
                    </a>
                  </div>
                  <div className="hidden mt-2 ml-2 pointer-events-auto desktop:block">
                    <span className="text-gray-50">·</span>
                  </div>
                  <div className="hidden mt-2 ml-2 pointer-events-auto desktop:block">
                    <a
                      className="text-sm font-medium hover:text-gray-900"
                      href="https://magpie.im/privacy.html"
                      target="_self"
                    >
                      Privacy policy
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Cookie Banner */}
      <div></div>
    </div>
  );
};

export default Login;
