import { UserRoles } from "@reducers/types";

const getFirstName = (value: string) => {
  if (!value) return 'merchant';

  const names = value.split(' ');
  return names[0];
}

const isValidEmail = (value: string | undefined) => {
  if (!value) return false;

  const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return email_pattern.test(value);
}

const isValidMobile = (value: string | undefined) => {
  if (!value) return false;
  
  const pattern = /^(9)[0-9]{2}[ ]?[0-9]{3}[ ]?[0-9]{4}$/;
  return pattern.test(value);
}

const isValidPhone = (value: string | undefined) => {
  if (!value) return false;
  
  const pattern = /^(639)|(\+639)|(09)|(9)[0-9]{2}[ ]?[0-9]{3}[ ]?[0-9]{4}$/;
  return pattern.test(value);
}

export interface IdType {
  id: number
  name: string
  code: string
  pattern: number[]
  sample?: string
  numeric: boolean
}

const idTypes: IdType[] = [
  { id: 1, name: 'Philippine National ID', code: 'national_id', pattern: [3,4,7,1], sample: 'PSN-1234-5678912-3', numeric: false},
  { id: 2, name: 'Social Security System (SSS)', code: 'sss', pattern: [2,7,1], sample: '12-1234567-0', numeric: true},
  { id: 3, name: 'Government Service Insurance System (GSIS)', code: 'gsis', pattern: [11], sample: '12345678901', numeric: true},
  { id: 4, name: 'Unified Multi-Purpose Identification (UMID)', code: 'umid', pattern: [4,7,1], sample: '1234-1234567-1', numeric: true},
  { id: 5, name: 'Professional Regulatory Commission (PRC) ID', code: 'prc_id', pattern: [7], sample: '1234567', numeric: true},
  { id: 6, name: 'Driver\'s License', code: 'drivers_license', pattern: [3,2,6], sample: 'D12-34-123456', numeric: false},
  { id: 7, name: 'Passport', code: 'passport', pattern: [15], sample: 'P1234567A', numeric: true},
  { id: 8, name: 'Pag-IBIG Card', code: 'pagibig', pattern: [4,4,4], sample: '1234-5678-9000', numeric: true},
  { id: 9, name: 'PhilHealth ID', code: 'philhealth', pattern: [4,4,4], sample: '1234-5678-9000', numeric: true},
  { id: 10, name: 'Postal ID', code: 'postal', pattern: [20], sample: 'PRN 100123456789 P', numeric: false},
  { id: 11, name: 'Tax Identification Number (TIN) ID', code: 'tin', pattern: [3,3,3,3], sample: '123-456-789-000', numeric: true},
  //{ id: 7, name: 'Alien Certificate of Registration (ACR)', code: 'acr', pattern: [25], sample: 'F000000123456', numeric: false},
];

const getIdTypes = () => {
  return idTypes;
}

const getIdByCode = (value: string) => {
  return idTypes.find((i) => i.code === value);
}

const getRoleTitle = (role?: string) => {
  if (!role) return '';
  switch (role) {
    case 'administrator': return 'Administrator';
    case 'developer': return 'Developer';
    case 'analyst': return 'Approver';
    case 'support':
    case 'support_specialist': return 'Maker';
    case 'viewer':
    case 'read_only': return 'View only';
    default: return '';
  }
}

const canRefund = (role?: string): boolean => {
  switch (role) {
    case UserRoles.ADMINISTRATOR:
    case UserRoles.DEVELOPER:
    case UserRoles.ANALYST: return true;
    case UserRoles.SUPPORT:
    case UserRoles.READ_ONLY:
    default: return false;
  }
}

const canSendReceipt = (role?: string): boolean => {
  switch (role) {
    case UserRoles.ADMINISTRATOR:
    case UserRoles.DEVELOPER:
    case UserRoles.ANALYST: 
    case UserRoles.SUPPORT: return true;
    case UserRoles.READ_ONLY:
    default: return false;
  }
}

const canAddEditCustomer = (role?: string): boolean => {
  switch (role) {
    case UserRoles.ADMINISTRATOR:
    case UserRoles.DEVELOPER:
    case UserRoles.ANALYST: 
    case UserRoles.SUPPORT: return true;
    case UserRoles.READ_ONLY:
    default: return false;
  }
}

const canSendRequest = (role?: string): boolean => {
  switch (role) {
    case UserRoles.ADMINISTRATOR:
    case UserRoles.DEVELOPER:
    case UserRoles.ANALYST: 
    case UserRoles.SUPPORT: return true;
    case UserRoles.READ_ONLY:
    default: return false;
  }
}

const canCreateBatch = (role?: string): boolean => {
  switch (role) {
    case UserRoles.ADMINISTRATOR:
    case UserRoles.DEVELOPER:
    case UserRoles.ANALYST: 
    case UserRoles.SUPPORT: return true;
    case UserRoles.READ_ONLY:
    default: return false;
  }
}

const canApproveRejectBatch = (role?: string): boolean => {
  switch (role) {
    case UserRoles.ADMINISTRATOR:
    case UserRoles.ANALYST: return true;
    case UserRoles.DEVELOPER:
    case UserRoles.SUPPORT: 
    case UserRoles.READ_ONLY:
    default: return false;
  }
}

export default {
  getFirstName,
  isValidEmail,
  isValidMobile,
  isValidPhone,
  getIdTypes,
  getIdByCode,
  getRoleTitle,
  canRefund,
  canSendReceipt,
  canAddEditCustomer,
  canSendRequest,
  canCreateBatch,
  canApproveRejectBatch,
}